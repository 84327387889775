export const questionsData = [
    {
        question: 'In what population is data for treatment with dupilumab not yet available?',
        answers: [
            {answer: 'Patients with hepatic impairment', status: 'default'},
            {answer: 'Elderly patients ', status: 'default'},
            {answer: 'Children with AD aged 6-11 years', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500
    },
    {
        question: 'What is autoimmunity?',
        answers: [
            {answer: 'Breakdown of healthy tissue in an attempt at repair and healing', status: 'default'},
            {answer: 'Breakdown of immune tolerance mechanisms;  immune system targets body’s own cells ', status: 'default'},
            {answer: 'An excessive immune reaction occurring from uncontrolled responses to foreign antigens ', status: 'default'},
        ],
        correctAnswer: 2,
        score: 500
    },
    {
        question: 'Which trial(s) in the Nucala SmPC met the primary endpoint of a significant reduction in the exacerbation rate for patients receiving Nucala vs placebo?',
        answers: [
            {answer: 'DREAM', status: 'default'},
            {answer: 'Venture', status: 'default'},
            {answer: 'Quest', status: 'default'},
            {answer: 'None of the above', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500,
    },
    {
        question: 'Which of the following statements is true regarding the QUEST primary endpoint of annualized rate of severe exacerbation events during the treatment period?',
        answers: [
            {answer: 'The 200 mg q2w dupilumab dose reduced the rate of severe asthma exacerbations compared with matching placebo treatment, and more effectively than the 300 mg q2w dupilumab dose.', status: 'default'},
            {answer: 'The 300 mg q2w dupilumab dose reduced the rate of severe asthma exacerbations compared with matching placebo treatment, and more effectively than the 200 mg q2w dupilumab dose.', status: 'default'},
            {answer: 'Both dupilumab doses reduced the rate of severe asthma exacerbations compared with matching placebo treatment, with no meaningful difference observed between the two dupilumab dose groups.', status: 'default'},
            {answer: 'Both dupilumab doses showed a non-significant reduction in the rate of severe asthma exacerbations compared with matching placebo treatment, with no meaningful difference observed between the two dupilumab dose groups.', status: 'default'},
        ],
        correctAnswer: 3,
        score: 500,
    },
    {
        question: 'In Quest study Dupixent 300 mg has improve lung function for patients with EOS more than 300 with …. ml ',
        answers: [
            {answer: '470', status: 'default'},
            {answer: '320', status: 'default'},
            {answer: '250', status: 'default'},
            {answer: 'None of above', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500,
    },
    {
        question: 'What is the Nucala eligibility criteria:',
        answers: [
            {answer: 'Blood eosinophilic levels greater than 150 cells/microliters at the start of treatment or greater than 300 anytime the past 12 months)', status: 'default'},
            {answer: 'Blood Eosinophils of 150 cells/microlitre at any point in time', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500
    },
    {
        question: 'In VENTURE, which of the following TEAEs were reported more frequently in the dupilumab arm versus the placebo arm (≥5% in the dupilumab arm and a difference of ≥2% versus the placebo arm)?',
        answers: [
            {answer: 'Eosinophilia', status: 'default'},
            {answer: 'Influenza', status: 'default'},
            {answer: 'Pruritus', status: 'default'},
            {answer: 'Viral upper respiratory tract infection', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500
    },
    {
        question: 'When we are going launch COPD in UAE if we succeeded to have parallel submission to FDA?',
        answers: [
            {answer: 'Jan 2024', status: 'default'},
            {answer: 'April 2024', status: 'default'},
            {answer: 'June 2024', status: 'default'},
            {answer: 'Dec 2024', status: 'default'},
        ],
        correctAnswer: 3,
        score: 500
    },
    {
        question: 'Dupixent GG ranks number ...... Worldwide When it come to turn over',
        answers: [
            {answer: '8', status: 'default'},
            {answer: '9', status: 'default'},
            {answer: '10', status: 'default'},
            {answer: '11', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500
    },
    {
        question: 'Dupixent have more than ...... Patients  world wide',
        answers: [
            {answer: '500  K', status: 'default'},
            {answer: '550 K', status: 'default'},
            {answer: '600 K', status: 'default'},
            {answer: '650 K', status: 'default'},
        ],
        correctAnswer: 2,
        score: 500
    },
    {
        question: 'How many countries Tezpire (Anti-TSLP) has been registered in GG ?',
        answers: [
            {answer: '1', status: 'default'},
            {answer: '2', status: 'default'},
            {answer: '3', status: 'default'},
            {answer: '4', status: 'default'},
        ],
        correctAnswer: 1,
        score: 500
    },
    {
        question: 'What dose adjustment is recommended for patients with mild or moderate renal impairment? ',
        answers: [
            {answer: 'Dose should be increased', status: 'default'},
            {answer: 'Dose should be reduced', status: 'default'},
            {answer: 'No dose adjustment is recommended', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'Which of the following statements regarding the prefilled autoinjector pen is correct?',
        answers: [
            {answer: 'Autoinjectors have no feedback as to when dose has been administered', status: 'default'},
            {answer: 'Injections should be administered at a ~90° angle', status: 'default'},
            {answer: 'Patient must manually inject the medication by pressing down the plunger rod', status: 'default'},
        ],
        correctAnswer: 2,
        score: 300
    },
    {
        question: 'Which of the following best describes humoral immunity?',
        answers: [
            {answer: 'Primarily driven by T-cells that recognise antigens produced by intracellular microbes', status: 'default'},
            {answer: 'Primarily driven by dendritic cells that phagocytose microbes', status: 'default'},
            {answer: 'Primarily driven by B-cells and involves the production of antibodies', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'What is the main function of plasma B-cells? ',
        answers: [
            {answer: 'To produce antibodies', status: 'default'},
            {answer: 'Trigger cytokine-secreting cells that regulate immunological activity', status: 'default'},
            {answer: 'Provide protection upon re-exposure to the same pathogen', status: 'default'},
        ],
        correctAnswer: 1,
        score: 300
    },
    {
        question: 'What happens in the cellular phase of inflammation?',
        answers: [
            {answer: 'Blood vessels adjacent to the injury vasodilate', status: 'default'},
            {answer: 'Cells in damaged tissue release inflammatory mediators', status: 'default'},
            {answer: 'Leukocytes migrate to the site of injury and eliminate the irritant', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'Which of the following innate immune cells are capable of phagocytosis?',
        answers: [
            {answer: 'Macrophages', status: 'default'},
            {answer: 'B-cells', status: 'default'},
            {answer: 'T-cells', status: 'default'},
        ],
        correctAnswer: 1,
        score: 300
    },
    {
        question: 'In which of the following conditions does mucus production, a type 2 inflammatory pathologic consequence, occur?',
        answers: [
            {answer: 'Atopic dermatitis', status: 'default'},
            {answer: 'Eosinophilic esophagitis', status: 'default'},
            {answer: 'Asthma', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'What is the approved dosing regimen for benralizumab according to the Fasenra SmPC?',
        answers: [
            {answer: '30 mg SC every 4 weeks', status: 'default'},
            {answer: '100 mg SC every 8 weeks', status: 'default'},
            {answer: '30 mg SC every 4 weeks for the first 3 doses, then every 8 weeks', status: 'default'},
            {answer: '100 mg SC every 4 weeks for the first 3 doses, then every 8 weeks', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'Which of the following characteristics applied to patients enrolled in the QUEST study except?',
        answers: [
            {answer: 'Adult and adolescent patients', status: 'default'},
            {answer: 'OCS-dependent asthma', status: 'default'},
            {answer: 'Persistent asthma', status: 'default'},
            {answer: 'Uncontrolled asthma despite receiving standard of care therapy', status: 'default'},
        ],
        correctAnswer: 2,
        score: 300
    },
    {
        question: 'What was the most frequently reported TEAE leading to permanent treatment discontinuation among patients in the dupilumab arms versus the placebo arms of the QUEST study?',
        answers: [
            {answer: 'Eosinophilia', status: 'default'},
            {answer: 'Injection site erythema', status: 'default'},
            {answer: 'Injection site pain', status: 'default'},
            {answer: 'Nausea', status: 'default'},
        ],
        correctAnswer: 2,
        score: 300
    },
    {
        question: 'In the VENTURE study, approximately __________ of patients were able to discontinue their daily OCS over a 24-week treatment period when treated with dupilumab.',
        answers: [
            {answer: '10%', status: 'default'},
            {answer: '30%', status: 'default'},
            {answer: '50%', status: 'default'},
            {answer: '90%', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300
    },
    {
        question: 'Which of the following statements is true regarding the primary efficacy endpoint of the VENTURE study?',
        answers: [
            {answer: 'The mean percent reduction in OCS dose at week 24 was greater in the dupilumab arm compared with the placebo arm, and there were no significant treatment-by-subgroup interactions observed for either eosinophil level or FeNO level at baseline.', status: 'default'},
            {answer: 'The mean percent reduction in OCS dose at week 24 was greater in the dupilumab arm compared with the placebo arm, and there were significant treatment-by-subgroup interactions observed for both eosinophil level and FeNO level at baseline.', status: 'default'},
            {answer: 'The mean percent reduction in OCS dose at week 24 was similar in the dupilumab arm versus the placebo arm, and dupilumab showed greater efficacy among patients with eosinophil levels ≥300/μL at baseline.', status: 'default'},
            {answer: 'The mean percent reduction in OCS dose at week 24 was similar in the dupilumab arm versus the placebo arm, and dupilumab showed greater efficacy among patients with high FeNO levels at baseline.', status: 'default'},
        ],
        correctAnswer: 1,
        score: 300
    },
    {
        question: 'In the SINUS-24 and SINUS-52 studies, what proportion of patients required rescue with systemic corticosteroids or nasal polyp surgery in the pooled dupilumab q2w and placebo groups?',
        answers: [
            {answer: '23% dupilumab q2w; 20% placebo', status: 'default'},
            {answer: '10% dupilumab q2w; 34% placebo', status: 'default'},
            {answer: '27% dupilumab q2w; 56% placebo', status: 'default'},
        ],
        correctAnswer: 2,
        score: 300
    },
    {
        question: 'Chronic rhinosinusitis is an inflammation of the nose and paranasal sinuses lasting longer than how many weeks?',
        answers: [
            {answer: '12 weeks', status: 'default'},
            {answer: '20 weeks', status: 'default'},
            {answer: '4 weeks', status: 'default'},
        ],
        correctAnswer: 1,
        score: 300,
    },
    {
        question: 'Complete the following sentence. Dupixent has demonstrated strong efficacy: for example, ________ fewer patients with CRSwNP required surgery compared to placebo at Week 52.',
        answers: [
            {answer: '61%', status: 'default'},
            {answer: '43%', status: 'default'},
            {answer: '83%', status: 'default'},
        ],
        correctAnswer: 3,
        score: 300,
    },
    {
        question: 'Which of the following is a component of the innate immune system?',
        answers: [
            {answer: 'Cytotoxic T-cells', status: 'default'},
            {answer: 'Phagocytes', status: 'default'},
            {answer: 'Plasma B-cells', status: 'default'},
        ],
        correctAnswer: 2,
        score: 100,
    },
    {
        question: 'Hypersensitivity is an excessive response to which of the following types of antigens?',
        answers: [
            {answer: 'Self-antigens', status: 'default'},
            {answer: 'Both foreign and self-antigens', status: 'default'},
            {answer: 'Foreign antigens', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'Which of the following statements best describes the innate immune response?',
        answers: [
            {answer: 'Provides initial protection against a wide range of pathogens; has no memory', status: 'default'},
            {answer: 'Provides initial protection and requires differentiation of lymphocytes; has memory', status: 'default'},
            {answer: 'Provides more specialized and effective defense; has memory', status: 'default'},
        ],
        correctAnswer: 1,
        score: 100,
    },
    {
        question: 'Which of the following statements regarding the key immune cells in type 2 inflammation are correct?',
        answers: [
            {answer: 'Both ILC2 and Th2 cells produce high levels of type 2 cytokines', status: 'default'},
            {answer: 'Th2 cells act as part of the innate immune response', status: 'default'},
            {answer: 'ILC2 cells stimulate a type 2 inflmmatory response by releasing alarmins such as TLSP', status: 'default'},
        ],
        correctAnswer: 1,
        score: 100,
    },
    {
        question: 'Which of the following is a role of type 2 inflammation in normal immune responses?',
        answers: [
            {answer: 'Defense against intracellular bacteria and viruses', status: 'default'},
            {answer: 'Combatting fungal and extracellular bacteria', status: 'default'},
            {answer: 'Protecting the body from helminths', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'Which of the following is a role of IL-4 but NOT IL-13?',
        answers: [
            {answer: 'Neuronal sensitization and itch ', status: 'default'},
            {answer: 'Fibrosis', status: 'default'},
            {answer: 'Production of IL-31 by Th2 cells', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'Type 2 inflammation has been implicated in all of the following disease states,:',
        answers: [
            {answer: 'Asthma', status: 'default'},
            {answer: 'Atopic dermatitis', status: 'default'},
            {answer: 'Inflammatory bowel disease', status: 'default'},
            {answer: 'Eosinophilic esophagitis', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'The primary symptoms of asthma encountered most often all of the below :',
        answers: [
            {answer: 'Coughing', status: 'default'},
            {answer: 'Dyspnea', status: 'default'},
            {answer: 'Tachycardia', status: 'default'},
            {answer: 'Wheezing', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'Fill in the blank. Nucala is an ______ antagonist monoclonal antibody indicated as an add-on treatment for severe refractory __________ asthma in adult patients.',
        answers: [
            {answer: 'IL-4; granulocytic', status: 'default'},
            {answer: 'IL-4; eosinophilic', status: 'default'},
            {answer: 'IL-5; eosinophilic', status: 'default'},
            {answer: 'IL-13; granulocytic', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'which of the following is not considered as ASTHMA treatment goal :',
        answers: [
            {answer: 'Reduction of EOS', status: 'default'},
            {answer: 'QOL', status: 'default'},
            {answer: 'Lung function Improvement', status: 'default'},
            {answer: 'Reduction of exacerbation rate', status: 'default'},
        ],
        correctAnswer: 1,
        score: 100,
    },
    {
        question: 'All the following are biomarkers for type 2 asthma',
        answers: [
            {answer: 'IgE', status: 'default'},
            {answer: 'FeNO', status: 'default'},
            {answer: 'Blood EOS', status: 'default'},
            {answer: 'TSLP', status: 'default'},
        ],
        correctAnswer: 4,
        score: 100,
    },
    {
        question: 'Which of the following statements regarding coexisting diseases in CRSwNP patients is correct?',
        answers: [
            {answer: 'Asthma is a rare comorbidity in CRSwNP patients', status: 'default'},
            {answer: 'Coexisting type 2 diseases are more commonly associated with CRSwNP compared with CRSsNP', status: 'default'},
            // tslint:disable-next-line:max-line-length
            {answer: 'No relationship has been observed between the presence of coexisting type 2 diseases and severity', status: 'default'},
        ],
        correctAnswer: 2,
        score: 100,
    },
    {
        question: 'Which of the following best defines nasal polyps?',
        answers: [
            {answer: 'Malignant lesions that form in the external portions of the nose', status: 'default'},
            {answer: 'Benign lesions that are restricted to the paranasal sinuses', status: 'default'},
            {answer: 'Benign lesions arising from the mucosa of the nasal passages', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'Which of the following is a limitation of oral systemic corticosteroids in CRSwNP?',
        answers: [
            {answer: 'They are associated with significant side effects', status: 'default'},
            {answer: 'They are not always able to fully access the inflamed tissue.', status: 'default'},
            {answer: 'They are not recommended by guidelines', status: 'default'},
        ],
        correctAnswer: 1,
        score: 100,
    },
    {
        question: 'Which of the following is considered the primary medical treatment for nasal polyps?',
        answers: [
            {answer: 'Intranasal corticosteroids', status: 'default'},
            {answer: 'Oral systemic corticosteroids', status: 'default'},
            {answer: 'Surgery', status: 'default'},
        ],
        correctAnswer: 1,
        score: 100,
    },
    {
        question: 'What is one key reason why surgery does not provide lasting relief for patients with CRSwNP?',
        answers: [
            {answer: 'CRSwNP surgery is a very complex procedure with low success rates', status: 'default'},
            {answer: 'It does not help with restoring loss of smell or taste', status: 'default'},
            {answer: 'It does not address the underlying type 2 inflammation that drives disease in  many CRSwNP patients', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
    {
        question: 'If a HCP has concerns about Dupixent\'s safety in CRSwNP patients, which of the following would be an appropriate response?',
        answers: [
            {answer: 'Dupixent is the only biologic to dually inhibit IL-4 and IL-13', status: 'default'},
            {answer: 'In SINUS-24/52, in the dupilumab 300 mg Q2W group, 2% of patients discontinued treatment due to AEs ', status: 'default'},
            {answer: 'Dupixent restored sense of smell in 2 out of 3 patients, with most improvement seen at Week 2', status: 'default'},
        ],
        correctAnswer: 2,
        score: 100,
    },
    {
        question: 'Complete the following sentence. Dupixent decreased markers of type 2 inflammation in patients with CRSwNP, with an ______ reduction in IL-5 in nasal secretions at Week 24.',
        answers: [
            {answer: '98%', status: 'default'},
            {answer: '71%', status: 'default'},
            {answer: '86%', status: 'default'},
        ],
        correctAnswer: 3,
        score: 100,
    },
];
