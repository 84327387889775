import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { GameControllerService } from './providers/game-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Dupixent-Quiz';
  players;
  constructor( public game: GameControllerService, private element: ElementRef ) {

  }

  ngOnInit(): void {
    this.players = this.game.players;
    let i = 1;
    const loaderInterval = setInterval(() => {
      if (document.readyState !== 'complete') {
        i++;
        this.element.nativeElement.querySelector('#bar-indicator').setAttribute('style', 'width:' + i + '%');
      } else {
        this.element.nativeElement.querySelector('#bar-indicator').setAttribute('style', 'width:98%');
        // console.log('Page has been loaded');
        this.hidePreloader();
        clearInterval(loaderInterval);
      }
      // console.log('Page has been loaded');
    }, 500);
    this.game.init();
  }

  ngAfterViewInit(): void {
    const pLevel0 = this.element.nativeElement.querySelector('.parallax-layer.level-0');
    const pLevel1 = this.element.nativeElement.querySelector('.parallax-layer.level-1');
    document.addEventListener('mousemove', (evt) => {
      // // console.log(evt, window.innerWidth, window.innerHeight);
      // // console.log(evt.clientX / window.innerWidth );
      pLevel0.style.transform = 'translateX(' + evt.clientX / window.innerWidth * 2 + '%)';
      // pLevel1.style.transform = 'translateX(' + evt.clientX / window.innerWidth * 2 + '%)';
      pLevel1.setAttribute('style', 'transform: translateX(' + evt.clientX / window.innerWidth * 5 + '%)');
    });
  }

  hidePreloader(): void {
    setTimeout(() => {
      this.element.nativeElement.querySelector('#preloader').setAttribute('style', 'opacity: 0');
    }, 1000);
    setTimeout(() => {
      this.game.showPreloader = false;
    }, 3000);
  }

  selectQuestion(id): void {
    const symbol = this.element.nativeElement.querySelector('.question-symbol[id="' + id + '"]');
    const status = symbol.getAttribute('status');
    // console.log(status);
    if (status === 'active' && this.game.questionSelected !== true) {
      symbol.setAttribute('status', 'inactive');
      this.game.selectQuestion(id);
    }
    // symbol.setAttribute('hidden', 'true');
    // symbol.setAttribute('style', 'display: none');
    // // console.log(symbol.getAttribute('id'));
  }


  selectPlayer(id): void {
    this.game.selectPlayer(id);
    // // console.log(id);
  }


}
