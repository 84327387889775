<div>
  <div *ngIf="game.showPreloader" class="preload-assets">
    <img src="../assets/imgs/answer-indicator-1.png" alt="">
    <img src="../assets/imgs/answer-indicator-2.png" alt="">
    <img src="../assets/imgs/answer-indicator-3.png" alt="">
    <img src="../assets/imgs/answer-indicator-4.png" alt="">
    <img src="../assets/imgs/close-question.png" alt="">
    <img src="../assets/imgs/question-bg.png" alt="">
    <img src="../assets/imgs/question-body.png" alt="">
    <img src="../assets/imgs/question-bottom.png" alt="">
    <img src="../assets/imgs/question-inactive.png" alt="">
    <img src="../assets/imgs/question-text.png" alt="">
    <img src="../assets/imgs/question-top.png" alt="">
    <img src="../assets/imgs/question-bottom.png" alt="">
    <img src="../assets/imgs/question-inactive.png" alt="">
  </div>
  <div id="container">
    <div class="parallax-layer level-0"></div>
    <div class="parallax-layer level-1"></div>
    <div class="overlay"></div>
    <app-player *ngFor="let player of players; index as id" [player]="player" [id]="id" (click)="selectPlayer(id)" [ngClass]="game.pClasses[id]"></app-player>
    <div class="questions-container grow">
      <div  *ngFor="let question of game.questions, index as qID" class="question-symbol score-{{question.score}}" [id]="qID" status="active" (click)="selectQuestion(qID)"> <span>{{qID + 1}}</span> </div>
    </div>
    <app-question [ngClass]="game.qClasses" *ngIf="game.showGameQuestion"></app-question>
    <div *ngIf="game.showInfo" class="info flash-slow">{{game.info}}</div>
    <div *ngIf="game.showInfo" class="questions-key"></div>
  </div>
  <div *ngIf="game.showPreloader" id="preloader">
    <div class="challenge-logo"></div>
    <div id="preloader-bar">
      <div id="bar-indicator"></div>
    </div>
    <div class="sponsered-logo"></div>
  </div>
</div>
