import { Injectable } from '@angular/core';
import { questionsData } from './questions';
import {Howl} from 'howler';


@Injectable({
  providedIn: 'root'
})
export class GameControllerService {
  showPreloader = true;
  questions = questionsData;
  questionToAnswer;
  questionSelected = false;
  questionAnswered = false;
  showGameQuestion = false;
  qClasses = {
    // grow: true,
    hide: false,
    shake: false
  };
  pClasses = [
    {
      // grow: true,
      not_active: false,
      attention: false
    },
    {
      // grow: true,
      not_active: false,
      attention: false
    },
    {
      // grow: true,
      not_active: false,
      attention: false
    },
    {
      // grow: true,
      not_active: false,
      attention: false
    },
    {
      // grow: true,
      not_active: false,
      attention: false
    },
  ];
  enableTimer = true;
  showInfo = true;
  info = 'Select a Question';
  playerSelected = false;
  playerSelectedId = 0;
  playerPower = 40;
  players = [
    {
    id: 0,
    name: 'CHALLENGERS',
    score: 0,
    power: this.playerPower,
    selected: false,
    hideRed: true,
    },
    {
    id: 1,
    name: 'DOMINATORS',
    score: 0,
    power: this.playerPower,
    selected: false,
    hideRed: true,
    },
    {
    id: 2,
    name: 'FIGHTERS',
    score: 0,
    power: this.playerPower,
    selected: false,
    hideRed: true,
    },
    {
    id: 3,
    name: 'CRUSHERS',
    score: 0,
    power: this.playerPower,
    selected: false,
    hideRed: true,
    },
    {
    id: 4,
    name: 'MINOTAURS',
    score: 0,
    power: this.playerPower,
    selected: false,
    hideRed: true,
    },
  ];
  sound = {
    // bg: Howl,
    impact: Howl,
    timer: Howl,
    question: Howl,
    lose: Howl,
    win: Howl,
  };
  interval;
  fps = 30;
  redFlag = 0;

  constructor() {
    // this.sound.bg = new Howl({
    //   src: '../assets/99sounds_loop_005.wav',
    //   // autoplay: true,
    //   loop: true,
    //   volume: 0.5,
    // });
    this.sound.impact = new Howl({
      src: '../assets/impact.wav',
      // autoplay: true,
      loop: false,
      volume: 0.7,
    });
    this.sound.timer = new Howl({
      src: '../assets/timer.mp3',
      // autoplay: true,
      loop: false,
      volume: 0.5,
    });
    this.sound.question = new Howl({
      src: '../assets/question.mp3',
      // autoplay: true,
      loop: false,
      volume: 0.5,
    });
    this.sound.lose = new Howl({
      src: '../assets/lose.wav',
      // autoplay: true,
      loop: false,
      volume: 1,
    });
    this.sound.win = new Howl({
      src: '../assets/win.mp3',
      // autoplay: true,
      loop: false,
      volume: 1,
    });
    // this.sound.bg.play();
  }

  init(): void {
    this.showGameQuestion = true;
    this.showGameQuestion = false;
  }

   showQuestion(): void {
    this.showGameQuestion = true;
    this.showInfo = false;
    this.info = 'Select Question';
    if (this.enableTimer === true) {
      this.timer('start');
    }
   }

   selectQuestion(id): void {
     this.questionToAnswer = this.questions[id];
     this.questionSelected = true;
     // console.log('Game: Question selected: ', id);
     // console.log('Game: Question data: ', this.questions[this.questionToAnswer]);
     this.info = 'Select Player';
   }
   selectPlayer(id): void {
     if (this.playerSelected === false && this.questionSelected === true) {
        this.players[id].selected = true;
        this.playerSelected = true;
        this.playerSelectedId = id;
        this.pClasses[id].attention = true;
        this.pClasses.forEach((p, index ) => {
          if (index !== id) {
            p.not_active = true;
          }
        });

        // console.log('Game: Player selected: ', id);
        this.showQuestion();
        this.sound.question.play();
     } else {
       this.info = 'Please Select Question First';
     }
   }

   hideQuestion(): void {
    this.qClasses.shake = false;
    // this.qClasses.grow = false;
    this.qClasses.hide = true;
    setTimeout(() => {
      this.showGameQuestion = false;
      this.playerSelected = false;
      this.questionSelected = false;
      this.playerSelectedId = null;
      this.qClasses.shake = false;
      this.qClasses.hide = false;
      // this.qClasses.grow = true;
      this.info = 'Select a Question';
      this.showInfo = true;
      this.pClasses.forEach((p) => {
          p.not_active = false;
          p.attention = false;
          // p.grow = false;
      });
      this.questionAnswered = false;
    }, 500);
   }

   checkAnswer(answerId): void {
      this.sound.timer.stop();
      if (this.questionAnswered === false) {
        this.questionAnswered = true;
        if (this.questionToAnswer.correctAnswer - 1 === answerId) {
          this.answerCorrect();
        } else {
          this.answerWrong(answerId, this.questionToAnswer.correctAnswer - 1);
          this.qClasses.shake = true;
          this.sound.lose.play();
        }
        this.info = 'Close Question Window';
        this.showInfo = true;
      }
   }

   answerCorrect(): void {
     clearInterval(this.interval);
     this.players[this.playerSelectedId].score += this.questionToAnswer.score;
     this.players[this.playerSelectedId].power = this.playerPower;
     this.players[this.playerSelectedId].hideRed = true;
     this.questionToAnswer.answers[this.questionToAnswer.correctAnswer - 1].status = 'correct';
     this.sound.win.play();
   }
   answerWrong(answer, correctAnswer): void {
    clearInterval(this.interval);
    this.players[this.playerSelectedId].score -= this.questionToAnswer.score;
    this.players[this.playerSelectedId].power = this.playerPower;
    this.players[this.playerSelectedId].hideRed = true;
    if (answer !== null) {
      this.questionToAnswer.answers[answer].status = 'wrong';
    }
    this.questionToAnswer.answers[correctAnswer].status = 'correct';
  }

  timer(status): void {
    // let interval
    if (status === 'start') {
      this.interval = setInterval(() => {
        this.players[this.playerSelectedId].power = this.players[this.playerSelectedId].power - 1;
        if (this.players[this.playerSelectedId].power < 10) {
          this.players[this.playerSelectedId].hideRed = false;
        }
        if (this.players[this.playerSelectedId].power === 11) {
          this.sound.timer.play();
         }
        if (this.players[this.playerSelectedId].power === 0) {
          clearInterval(this.interval);
          this.checkAnswer(null);
        }
      }, 1000);
    } else {
      clearInterval(this.interval);
    }
  }

}
