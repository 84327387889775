import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PlayerComponent } from './components/player/player.component';
import { QuestionComponent } from './components/question/question.component';
import { GameControllerService } from './providers/game-controller.service';

@NgModule({
  declarations: [
    AppComponent,
    PlayerComponent,
    QuestionComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [
    GameControllerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
