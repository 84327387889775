import { Component, OnInit } from '@angular/core';
import { GameControllerService } from 'src/app/providers/game-controller.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  question;
  constructor( public game: GameControllerService) { }

  ngOnInit(): void {
    this.question = this.game.questionToAnswer;
  }

  selectAnswer(answerId): void {
    this.game.checkAnswer(answerId);
    // console.log(answerId);
  }

  closeQuestion(): void {
    this.game.hideQuestion();
  }

}
