import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { GameControllerService } from '../../providers/game-controller.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnChanges {
  // @Input() player;
  @Input() id;
  player;
  redStyles;
  redBarStyles;
  redHealthStyles;


  constructor( public game: GameControllerService ) { }

  ngOnInit(): void {
    this.player = this.game.players[this.id];
    // console.log(this.player);
    this.redBarStyles = this.player.redBarStyles;
    this.redHealthStyles = this.player.redHealthStyles;
  }

  ngOnChanges(): void {
    // console.log('changed', this.player);
  }

}
