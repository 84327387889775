<div>
    <div class="question-top"></div>
    <div class="question-bottom"></div>
    <div class="answers-container">
        <div *ngFor="let answer of question.answers, index as answerID" class="answer" [id]="answerID" (click)="selectAnswer(answerID)">
            <div class="answer-status {{answer.status}}" status=""></div>
            <p class="{{answer.answer.length > 120 ? 'small' : ''}}">{{answer.answer}}</p>
            <div class="answer-click"></div>
        </div>
    </div>
    <div class="{{question.question.length > 180 ? 'question small' : 'question'}}">{{question.question}}</div>
    <div *ngIf="game.showInfo" class="close-question" (click)="closeQuestion()"></div>
</div>
